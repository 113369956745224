import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { hasNoPaidContent } from 'redux/modules/front';
import { getTaboolaConfig, paidContentList } from 'lib/paidContentUtils';
import Ad from 'components/Ad';

import styles from './styles.module.scss';

const calledTaboola = {
  alacarte: false,
  teaseList: false,
};
export const PaidContent = (props) => {
  const [adRendered, setAdRendered] = useState(null);
  const dispatch = useDispatch();

  const {
    adLoaded,
    className,
    isMobileOnlyComponent,
    type,
    vertical,
    isMSNBCRightRail,
  } = props;
  const taboolaConfig = getTaboolaConfig({ isMobileOnlyComponent, type, vertical });
  let Element = 'article';
  let adSlot = 'nativethreeup';

  /*
    nativetopvideolist will render on all
    right rail msnbc alacartes.
    this does not check if it's the first
    to render it. At the moment it's not
    an issue because curators do not
    use multiple alacartes on zone B.
  */
  if (isMSNBCRightRail) {
    adSlot = 'nativetopvideolist';
  }

  if (type === paidContentList) {
    Element = 'li';
    adSlot = 'nativeultradense';
  }

  useEffect(() => {
    if (adRendered === false || adRendered === null) {
      const { mode, container, placement } = taboolaConfig;
      /* eslint-disable no-underscore-dangle */
      if (type === paidContentList && calledTaboola.teaseList === false) {
        calledTaboola.teaseList = true;
        window._taboola = window._taboola || [];
        window._taboola.push({
          mode,
          container,
          placement,
          target_type: 'mix',
        });
      } else if (type === 'alacarte' && calledTaboola.alacarte === false) {
        calledTaboola.alacarte = true;
        window._taboola = window._taboola || [];
        window._taboola.push({
          mode,
          container,
          placement,
          target_type: 'mix',
        });
      } else if (type !== 'alacarte' && type !== paidContentList) {
        window._taboola = window._taboola || [];
        window._taboola.push({
          mode,
          container,
          placement,
          target_type: 'mix',
        });
      }
      /* eslint-enable no-underscore-dangle */
    }
  }, [adRendered]);

  const adHandler = (mpsAd) => {
    mpsAd.onRender((event) => {
      if (!event) {
        return;
      }
      if (event.empty) {
        dispatch(hasNoPaidContent());
      }
      setAdRendered(!event.empty);
      adLoaded(true);
    });
    mpsAd.render();
  };
  return (
    <Element className={`${styles.adWrapper} ${className}`}>
      <Ad
        activeTab={false}
        adClass={styles.nativeAdContainer}
        handler={adHandler}
        refreshInterval={0}
        slot={adSlot}
      />
      {!adRendered && (
        <div id={taboolaConfig.container} />
      )}
    </Element>
  );
};

PaidContent.propTypes = {
  adLoaded: PropTypes.func,
  className: PropTypes.string,
  isMobileOnlyComponent: PropTypes.bool,
  type: PropTypes.string.isRequired,
  vertical: PropTypes.string.isRequired,
  isMSNBCRightRail: PropTypes.bool,
};

PaidContent.defaultProps = {
  adLoaded: Function.prototype,
  className: null,
  isMobileOnlyComponent: false,
  isMSNBCRightRail: false,
};
