import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { timeFrom, getFormattedTimeString } from 'lib/DateTime';

import styles from './styles.module.scss';

function TimeAgo(props) {
  const { t } = useTranslation();

  const { dateString, chatStyle, displayTime } = props;
  const daysFromDate = timeFrom(dateString);
  const formattedTime = getFormattedTimeString(dateString);

  const timeAgo = `${t('time_ago', { time: daysFromDate })}`;
  const time = displayTime ? `${timeAgo} / ${formattedTime}` : timeAgo;
  const justNow = daysFromDate === '0m';
  const formattedDaysFromDate = justNow ? `${t('Just now')}` : time;

  return (
    <div
      className={
        classNames(
          styles.timestamp,
          chatStyle && styles.chatStyleTimeStamp,
          justNow && styles.justNow,
        )
      }
      data-testid="timestamp"
    >
      {formattedDaysFromDate}
    </div>
  );
}

TimeAgo.propTypes = {
  dateString: PropTypes.string.isRequired,
  chatStyle: PropTypes.bool,
  displayTime: PropTypes.bool,
};

TimeAgo.defaultProps = {
  chatStyle: false,
  displayTime: true,
};

export { TimeAgo };
